
/**
 * Type Guard and filter to remove `undefined | null` values from an array.
 *
 * @example
 *     ['bar', 'foo', undefined, null]
 *       // Remove nulls
 *       .filter(removeNulls)
 *       // a & b are guaranteed to exist and Typscript gets it!
 *       .sort((a, b) => { ... })
 */
export const removeNulls = <S>(value: S | undefined): value is S => value != null;

/**
 * Remove duplicates from an array.
 */
export const dedupe = <T>(list: T[]) => [...new Set(list)]

/**
 * Generate a list of items.
 */
export function listOf<T>(count: number, generate: (i: number) => T = (i: number) => i as T) {
  const list = []
  for (let i = 0; i < count; i++) {
    list.push(generate(i))
  }
  return list
}

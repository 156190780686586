import React from 'react'

import { combineClasses } from '~/util'
import { Action } from '../buttons'

import styles from './Card.module.scss'

interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * Using this flag will remove
   *   the default background color and borders.
   */
  transparent?: boolean
  /**
   * Passing a `to` prop will make the card a link.
   */
  to?: string
}

/**
 * Use this generic card container as the base for custom cards.
 * This is a good place to put any functionality/styling that should
 * be the same across all cards.
 */
export let Card = React.forwardRef<HTMLDivElement, CardProps>(
  (
    { className, transparent, children, to, 'aria-label': ariaLabel, ...rest },
    cardRef
  ) => {
    return (
      <div
        ref={cardRef}
        data-testid="Card"
        className={combineClasses(
          styles.Card,
          className,
          transparent ? styles.transparent : null
        )}
        {...rest}
      >
        {to && (
          <Action
            data-testid="cardLink"
            className={styles.link}
            to={to}
            aria-label={ariaLabel}
            unstyled
          />
        )}
        {children}
      </div>
    )
  }
)

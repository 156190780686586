import React from 'react'

import { PageLoader, BundleLoadError } from './components'
import { env } from './service'

import './App.scss'

console.log('--- STARTING APP ---')
env.log()

// Create two separate bundles so we don't bloat
// the production app with mock data generators.
const Main = env.mock
  ? React.lazy(() => import('./pages/main/WithMocks'))
  : React.lazy(() => import('./pages/main/WithServer'))

export default function App(props) {
  return (
    <div className="App">
      <BundleLoadError>
        <React.Suspense fallback={<PageLoader />}>
          <Main {...props} />
        </React.Suspense>
      </BundleLoadError>
    </div>
  )
}

import React from 'react'

import { formatPhoneNumberURL } from '~/util'
import { CallAction, SMSAction } from './ReusableActions'
import { ActionProps } from './Action'

export interface CallOrTextActionProps extends Omit<ActionProps, 'type'> {
  number: string
  type?: 'sms' | 'tel'
  showNumber?: boolean
}

/**
 * Show either a phone or text message icon link with the correct `href`
 * based on the `type` parameter.
 */
export const CallOrTextAction = React.forwardRef<any, CallOrTextActionProps>(
  (
    {
      number,
      // If the phone number contains an sms indicator, use treat this
      // as an sms button. Otherwise, default to a call button.
      type = String(number).indexOf('sms') > -1 ? 'sms' : 'tel',
      showNumber,
      ...rest
    }: CallOrTextActionProps,
    ref
  ) => {
    const phoneNumber =
      String(number).indexOf(':') > -1 ? number.split(':')[1] : number
    const href = formatPhoneNumberURL(phoneNumber, type)

    if (type.toLowerCase() === 'sms') {
      return (
        <SMSAction
          data-testid="CallOrTextAction"
          to={href}
          title="Text Us"
          transparent
          feel="button"
          size="m"
          unrouted
          {...rest}
          ref={ref}
        >
          {showNumber ? phoneNumber : null}
        </SMSAction>
      )
    } else {
      return (
        <CallAction
          data-testid="CallOrTextAction"
          to={href}
          title="Call Us"
          transparent
          feel="button"
          size="m"
          unrouted
          {...rest}
          ref={ref}
        >
          {showNumber ? phoneNumber : null}
        </CallAction>
      )
    }
  }
)
